import React from 'react';
import '../css/components/primary-button.css';
import LoadingIcon from './LoadingIcon';

interface Props {
    onClick: () => void
    title: string
    loading?: boolean
    disabled?: boolean
}

function PrimaryButton ({ onClick, title, loading, disabled }: Props) {
    let classes = 'button-primary';
    if (loading) {
        classes += ' loading';
    }
    return (
        <button
            className={classes}
            onClick={!loading ? onClick : () => null }
            disabled={disabled}
            aria-label={title}
        >
            {!loading ? title : <LoadingIcon/>}
        </button>
    );
}

PrimaryButton.defaultProps = {
    loading: false,
    disabled: false
};

export default PrimaryButton;
