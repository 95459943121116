import React from 'react';
import '../css/components/loading-icon.css';

function LoadingIcon () {
    return (
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default LoadingIcon;
