import React, { useEffect, useState } from 'react';
import './css/App.css';
import PrimaryButton from './components/PrimaryButton';

function App () {
    const queryParameters = new URLSearchParams(window.location.search)
    const hash = queryParameters.get('h')
    const cartId = queryParameters.get('cart_id')
    const disabled = !cartId;

    const [error, setError] = useState<string | null>(null);
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showButton, setShowButton] = useState<boolean>(true);

    useEffect(() => {
        if (disabled) {
            setError('Invalid request: User account not found.');
        }
    }, [disabled]);

    const submitUnsubscribe = () => {
        setLoading(true);
        fetch(String(process.env.REACT_APP_API_URL) + '/api/carts/' + String(cartId) + '/unsubscribe-emails/', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token: hash
            })
        }).then(response => {
            if (response.ok) {
                setSuccessMsg('You have been unsubscribed.');
            } else if (response.status === 400 || response.status === 404) {
                setError('There was an error with your request.');
            } else {
                setError('There was an unknown error.');
            }
            setShowButton(false);
            setLoading(false);
        }).catch(() => {
            setError('There was an unknown error.');
            setShowButton(false);
            setLoading(false);
        });
    }
    return (
        <div className="App">
            <header className="App-header">
                <h1>Unsubscribe</h1>
            </header>
            <div>
                <p>
                    Are you sure you want to unsubscribe from all email communications?
                </p>
                {successMsg && <p className={'success-msg'}>{successMsg}</p>}
                {error && <p className={'error-msg'}>{error}</p>}
                {showButton &&
                    <PrimaryButton onClick={submitUnsubscribe} title={'Yes, unsubscribe me'} loading={loading} disabled={disabled}/>}
            </div>
        </div>
    );
}

export default App;
